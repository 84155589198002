<div class="m-content" style="background-color: #f2f3f8; padding: 1rem 1rem 2.5rem">
  <div>
    <div class="m-form m-form--label-align-right m-margin-top-20">
      <div class="row justify-content-between">
        <div class="col">
          <div class="input-group input-group m-form__group" style="padding-top: 0rem">
            <button
              type="button"
              class="bg-blue-700 white px-3 py-2.5 rounded-lg text-base text-white"
              name="filterIsOpen"
              [value]="filterIsOpen"
              (click)="filterIsOpen = !filterIsOpen"
            >
              {{ filterIsOpen ? 'Close' : '' }} Filter
            </button>
          </div>
        </div>
        <div class="expense-buttons col" style="text-align:end">
          <div *ngIf="!_filters?.recovery" style="display: flex">
            <div class="mr-4">
              <button class="bg-blue-700 white px-3 py-2.5 rounded-lg text-base text-white flex items-center" title="Recovery deleted quotes" (click)="updateFilter({recovery:true})">
                <i class="fa-solid fa-trash-can-arrow-up text-xs mr-2"></i> Recovery
              </button>
            </div>
            <div class="mr-2" *appHasPermission="'expenses_create'">
              <button class="bg-gray-200 white px-3 py-2.5 rounded-lg text-base text-gray-900 flex items-center" (click)="create()"
                 title="Create Expense">
                 <i class="fa-solid fa-plus text-xs mr-2"></i> Create
              </button>
            </div>
          </div>
          <div *ngIf="_filters?.recovery">
            <button class="bg-gray-200 white px-3 py-2.5 rounded-lg text-base text-gray-900 flex items-center" (click)="updateFilter({recovery:false})">
              <i class="fa-solid fa-chevron-left text-xs mr-2"></i> Back
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2 mb-2">
        <app-expense-filter
          [hidden]="!filterIsOpen"
          [filters]="_filters"
          [currentUser]="currentUser"
          [accountsCharts]="accountsCharts"
          [categories]="categories"
          (onSet)="updateFilter($event)"
          (onReset)="resetFilter($event)"
        ></app-expense-filter>
      </div>
      
    </div>

    <div class="m-content" style="padding-top: 1rem" id="scrollIntoTop">
      <div class="m-portlet m-portlet--mobile" style="box-shadow: none; background-color: none">
        <div *ngIf="!filterExists" class="pb-1" style="background: #f2f3f8;">
          From {{firstDay | date : 'MM/dd/yy' }} to {{lastDay | date : 'MM/dd/yy'}}
        </div> 
        <div style="display:flex; background: #f2f3f8; padding-bottom: 0.5rem;">
            @defer(when !loading && loaded){
              @for(s of stats; track $index;){
                <div [ngClass]="['sub-status-'+ s._id.toLocaleLowerCase()]" class="flex items-center p-2 rounded-lg mr-3">
                  <div class="flex items-center" [ngClass]="['stats__count', 'sub-status-count-'+ s._id.toLocaleLowerCase()]">{{s.count}}</div>
                  <div class="ml-2">
                    <div class="stats__status">{{getStatusName(s._id)||s._id}}</div>
                    <span class="stats__amount">${{s.amount|myCurrency}}</span>
                  </div>
                </div>
              }
            }@placeholder {
              <div class="w-1/5">
                <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'63px'"></app-preloader>
              </div>
              
            }
            
        </div>

        <div class="flex justify-between pb-2 pt-2" style="background: #f2f3f8">
          <div style="width: 40%;">
            <app-filters [status]="statuses.main" [filters]="_filters" (filterSet)="updateFilter($event)"></app-filters>
            <div style="width:60%" class="mt-1">
              <app-filters
              *ngIf="_filters?.status == 'ACCEPTED'"
              [status]="statuses.accepted"
              [filters]="_filters"
              [property]="'subStatus'"
              (filterSet)="updateFilter($event)"
            ></app-filters>
            </div>
            
          </div>
          <div class="flex justify-end items-center mt-3">
            <!-- <div style="width:110px" class="mr-3">
              <app-select
                [value]="pagination.limit+''"
                [list]="limitOptions"
                [label]="'Limit'"
                [keyName]="{key:'key', name:'name'}"
                (onSave)="updateFilter({ limit: $event })"
              ></app-select>
            </div> -->
            <span class="font-normal" style="color: rgba(0, 0, 0, 0.555);"><span class="font-bold"  style="color: rgba(0, 0, 0, 0.452);">{{totalCount}}</span> total</span>
          </div>
        </div>

        @defer(when !loading && loaded) {
          <ngx-datatable
              #table
              style="background-color: #f2f3f8"
              class="custom-datatable"
              [columnMode]="'flex'"
              [headerHeight]="50"
              [footerHeight]="70"
              [rowHeight]="'auto'"
              [limit]="filters?.limit || pagination.limit"
              [rowClass]="rowClass"
              [rows]="expenses"
              [externalPaging]="true"
              [count]="totalCount"
              [offset]="pagination.offset"
              (page)="setPage($event)"
              [externalSorting]="true"
            >
              <ngx-datatable-column name="STATUS" prop="status" [resizeable]="false" [sortable]="false" [flexGrow]="0.5"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>              
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="STATUS" prop="status" [resizeable]="false" [sortable]="false" [flexGrow]="2.6"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Status</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div  *ngIf="row.status" class="flex flex-col gap-1 items-start">
                      <span class="font-medium adaptive-font px-2 rounded-md" [ngClass]="['sub-status-' + row.status.toLocaleLowerCase()]">{{
                        row.status.split('_').join(' ')
                      }}</span>
                      <span *ngIf="row?.closed" [ngClass]="['m-badge', 'badge-style', 'bg-gray-100 text-gray-900']">CLOSED</span>
                    </div>                
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="EXPENSE_NUMBER" prop="expenseNumber" [resizeable]="false" [sortable]="false" [flexGrow]="2.6"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Number</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="flex items-center">
                    <a href="javascript:void(0);" (click)="edit(row)"  class="text-blue-800 text-lg font-semibold" 
                    title="View Expense Details">{{row.expenseNumber}}</a>
                    <a *ngIf="row?.orderId" class="ml-2 flex" [routerLink]="'/shop/'+row?.orderId" target="_blank" title="Open Work Order">
                      <i class="fa-solid fa-arrow-up-right-from-square text-blue-800"></i>
                    </a>
                  </div>
                  
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="AMOUNT" prop="amounts" [resizeable]="false" [sortable]="false" [flexGrow]="2.6"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Amount</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span class="text-gray-900 text-lg font-medium">${{ row?.amounts | sum:'value' | myCurrency}}</span>
                  <span *ngIf="row?.tags?.includes('WARNING')" class="ml-2 text-warning" title="WARNING">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="DATE" prop="date" [resizeable]="false" [sortable]="false" [flexGrow]="4"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Date</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="flex flex-wrap gap-2 p-0">
                    <div  *ngIf="row?.expenseDate" class="flex items-center">
                      <span class="text-sm px-3 py-1 rounded-md bg-gray-100 text-gray-900" title="Expense Date">D</span>
                      <small class="ml-1 adaptive-font text-black opacity-50">{{row.expenseDate | date: 'MM/dd/yy': 'UTC'}}</small>
                    </div>
                    <div  *ngIf="row?.createdAt" class="flex items-center">
                      <span class="text-sm px-3 py-1 rounded-md bg-gray-100 text-gray-900" title="Created Date">C</span>
                      <small class="ml-1 adaptive-font text-black opacity-50">{{row.createdAt | date: 'MM/dd/yy': 'UTC'}}</small>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="RESOURCES" prop="resources" [resizeable]="false" [sortable]="false" [flexGrow]="3.6"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Resource</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  @for(amount of row?.amounts; track $index){
                    <div class="flex items-center text-blue-800">
                      <a  
                        *ngIf="amount?.resourceId"
                        (click)="openResource(amount?.resource, amount?.resourceId)" 
                        class="adaptive-font font-medium"
                        href="javascript:void(0);"
                        title="View Trip">
                        <i class="fa-solid fa-link mr-1 text-xs"></i> {{amount.resourceName}} 
                      </a>
                    </div>
                  }
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="EMPLOYEE" prop="employee" [resizeable]="false" [sortable]="false" [flexGrow]="3"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Employee</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row?.employee?.employeeId">
                    <a
                        title="Click to show full information about {{ row.name }}"
                        [routerLink]="[]"
                        class="font-medium inline-block adaptive-font px-2 rounded-md bg-blue-100 text-blue-900"
                        (click)="openContactModal(row?.employee?.employeeId)"
                      >
                      <span *ngIf="row.reimbursement" title="Need For Reimbursement" class="text-blue-900">
                        <i  class="fa fa-dollar-sign text-base font-medium"></i>
                      </span>
                      <span>
                        {{row.employee.name}}
                      </span>
                    </a>
                    
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="CATEGORY" prop="category" [resizeable]="false" [sortable]="false" [flexGrow]="4.5"> 
                <ng-template let-sort="sortFn" let-column="column" ngx-datatable-header-template>
                  <label (click)="sort()" class="font-poppins font-semibold text-black ml-2 text-sm">Category</label>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row?.category && categories">
                    <span class="adaptive-font inline-block font-medium px-2 rounded-md bg-gray-100 text-gray-900" >
                    {{categories[row.category]}}
                  </span>
                </div>
                </ng-template>
              </ngx-datatable-column>   
              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >

                <div class="pagination-cantainer">
                  <app-pagination 
                    *ngIf="expenses?.length" 
                    (pageChange)="setPage($event)" 
                    [total]="totalCount" 
                    [limit]="pagination?.limit" 
                    [currentPage]="page"
                  ></app-pagination>
                </div>

                </ng-template>
              </ngx-datatable-footer>         
            </ngx-datatable>
          }@placeholder{
            <div style="background-color: #f2f3f8">
              <app-preloader [type]="'CONTENT_LIST'" [count]="5" [customHeight]="'70px'"></app-preloader>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
