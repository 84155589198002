import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  private patchSubject = new BehaviorSubject<any>(null)

  patchLocal$ = this.patchSubject.asObservable()

  constructor() { }

  setPatchLocal(value: Object) {
    this.patchSubject.next(value)
  }
}
