<div class="h-100" style="display:flex; flex-direction: column; justify-content: space-between;">
  <form [formGroup]="expenseForm" class="row">
    <div class="w-100">
      <div class="p-0">
        <div class="w-100 mb-4">
          <div class="relative bg-gray-50 rounded-lg w-full">
            <select 
              formControlName="accountChart"
              class="bg-gray-50 block px-2.5 w-full input_style text-sm text-gray-500 bg-transparent font-poppins rounded-lg z-10 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              style="border: 1px solid rgb(209, 213, 219);"
              >
                <option value="">Select</option>
                @for(group of accountsCharts; track $index){
                  @if(group.parentId === null){
                    <option value="" disabled class="font-weight-bold bg-light">Without Parent</option>
                  }
                  @for(account of group.accounts; track account._id){
                    <option [value]="account._id"  [ngClass]="account._id === group.parentId ? ['font-weight-bold bg-light'] : ['ml-3']">{{account._name}}</option>
                  }
                  
                }
            </select>
            <label class="absolute bg-gray-50 rounded-2xl text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:py-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Account</label>
              <fieldset class="jss1"><legend class="jss3"><span>Account</span></legend></fieldset>
          </div>
        </div>   
        <div class="w-100 mb-4">
          <div
            *ngIf="expenseForm.get('vendorId').value"
            class="relative"
          >
            
            <div  class="relative bg-gray-50 rounded-lg w-full">
              <a
                id="typeahead-focus"
                type="text"
                [routerLink]="[]"
                (click)="openContactModal(expenseForm.get('vendorId').value)"
                class="flex items-center bg-gray-50 px-2.5 w-full input_style text-sm text-blue-600 bg-transparent font-poppins rounded-lg z-10 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                style="border: 1px solid rgb(209, 213, 219);"
              ><i class="fa-solid fa-arrow-up-right-from-square mr-1"></i> {{expenseForm.get('vendorName').value}}</a>
              <label class="absolute bg-gray-50 rounded-2xl text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:py-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Vendor</label>
              <fieldset class="jss1"><legend class="jss3"><span>Vendor</span></legend></fieldset>

              <app-btn-confirm
                class="removeBtn top-2 absolute-right-2" [isReadOnly]="isReadOnly" (onConfirm)="clearVendor()"></app-btn-confirm>
            </div>
          </div>

            <app-search
              *ngIf="!expenseForm.get('vendorId').value"
              [resource]="'CONTACT'"
              [label]="'Vendor'"
              [isReadOnly]="isReadOnly" 
              [idInfo]="'claim_Vendor'"
              [options]="{noFocus: true, createContact: true, resource: 'EXPENSE', createType: 'vendor'}"
              [method]="'search'"
              [filter]="{type: 'VENDOR', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
              (onChange)="setVendor($event)"
            ></app-search>
            
        </div>
        <div class="w-100 mb-4 flex gap-1">
          <div class="w-50">
            <app-select [list]="statuses" [value]="expenseForm.get('status').value" [label]="'Status *'"
              [placeholder]="''" [isReadOnly]="currentUser?._id === expenseForm.get('employeeId').value"
              (onSave)="setValue($event, 'status')"></app-select>
            <div *ngIf="
                expenseForm.get('status').invalid &&
                (expenseForm.get('status').dirty || expenseForm.get('status').touched)
              " class="invalid-feedback">
              Please select Status!
            </div>

            <app-btn-toggle class="doNotLock"
              [value]="expenseForm.get('closed').value ? true : false" [idInfo]="'quote-is-delivered'"
              [label]="'Close Order'" [options]="{ disabled: false }"
              (onToggle)="setValue($event, 'closed')"></app-btn-toggle>
          </div>
          <div class="w-50">
            <app-date class="d-block" [parent]="expenseForm.value" [label]="'Expense Date'" [startKey]="'expenseDate'"
              [options]="{ timezone: false, disabled:isReadOnly, rounded_top: expenseForm.get('closed').value === true}"
              (onSave)="onDateChange($event, 'expenseDate')"></app-date>
            <div *ngIf="
                expenseForm.get('expenseDate').invalid &&
                (expenseForm.get('expenseDate').dirty || expenseForm.get('expenseDate').touched)
              " class="invalid-feedback">
              Please input Expense Date!
            </div>

            <app-date *ngIf="expenseForm.get('closed').value === true" [parent]="expenseForm.value" [label]="'Transaction Date'" [startKey]="'txnDate'"
              [options]="{ timezone: false, disabled:isReadOnly,  rounded_bottom: expenseForm.get('closed').value === true}" (onSave)="onDateChange($event, 'txnDate')"></app-date>
            <div *ngIf="
                expenseForm.get('txnDate').invalid &&
                (expenseForm.get('txnDate').dirty || expenseForm.get('txnDate').touched)
              " class="invalid-feedback">
              Please input Transaction Date!
            </div>
          </div>
        </div>
        <div class="w-100 mb-4 flex gap-1">
          <div class="w-50">
            <div *ngIf="expenseForm.get('employeeId').value" class="relative bg-gray-50 rounded-lg w-full">
              <a
                id="typeahead-focus"
                type="text"
                [routerLink]="[]"
                (click)="openContactModal(expenseForm.get('employeeId').value)"
                class="bg-gray-50 flex items-center px-2.5 w-full input_style text-sm text-blue-600 bg-transparent font-poppins rounded-lg z-10 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                style="border: 1px solid rgb(209, 213, 219);"
              ><i class="fa-solid fa-arrow-up-right-from-square mr-1"></i>{{expenseForm.get('employeeName').value}}</a>
              <label class="absolute bg-gray-50 rounded-2xl text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:py-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Employee</label>
              <fieldset class="jss1"><legend class="jss3"><span>Employee</span></legend></fieldset>
              <app-btn-confirm *ngIf="currentUser?._id !== expenseForm.get('employeeId').value"
                class="removeBtn top-2 absolute-right-2" [isReadOnly]="isReadOnly" (onConfirm)="clearEmployee()"></app-btn-confirm>
            </div>
            <app-search
              *ngIf="!expenseForm.get('employeeId').value"
              [resource]="'CONTACT'"
              [label]="'Employee'"
              [accountId]="currentUser?.company._id"
              [isReadOnly]="isReadOnly" 
              [idInfo]="'claim_Employee'"
              [options]="{hideCreate: true}"
              [method]="'search'"
              [filter]="{limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
              (onChange)="setEmployee($event)"
            ></app-search>
          </div>
          <div class="w-50">
            <app-multi-select 
              [idInfo]="'expense-tags'"
              [visibleCount]="1"
              [data]="tags" [value]="expenseForm.value.tags" 
              [label]="'Tags'" [keyName]="{key:'value', name: 'label'}" 
              (onSave)="setValue($event, 'tags')">
            </app-multi-select>  
          </div>
        </div>
        <div class="w-100 mb-4 flex gap-1">
          <div class="w-50">
            <app-input [value]="expenseForm.get('merchant').value" [label]="'Merchant'"
              [isReadOnly]="isReadOnly" (onSave)="expenseForm.get('merchant').setValue($event)"></app-input>
          </div>
          <div class="w-50">
            <app-select [label]="'Category'" [value]="expenseForm.get('category').value" [list]="categoriesArray" [isReadOnly]="isReadOnly" 
            (onSave)="expenseForm.get('category').setValue($event)" [invalid]="expenseForm.get('category').invalid && (expenseForm.get('category').dirty || expenseForm.get('category').touched)"></app-select>
            <div *ngIf="
                expenseForm.get('category').invalid &&
                (expenseForm.get('category').dirty || expenseForm.get('category').touched)
                " class="invalid-feedback">
                Please input Category!
            </div>
          </div>
        </div>
        @for(amount of expenseForm.get('amounts').value; track $index; let i = $index){
          <div [ngClass]="[!$last ? 'mb-2' : '']" class="flex gap-1">
            <div class="w-1/3">
              <app-price  [value]="amount?.value" [label]="'Amount *'" [isReadOnly]="isReadOnly"
                (onSave)="updateAmount(i,{value: $event})"></app-price>
            </div>
            <div *ngIf="
            expenseForm.get('status').value === 'ACCEPTED' && expenseForm.get('paymentType').value === 'reimburse'
            ||
            expenseForm.get('status').value === 'DENIED' && expenseForm.get('paymentType').value === 'companyCard'
            " class="w-1/3">
              <app-price  [value]="amount?.deductValue || amount?.value" [label]="'Deduction'" [isReadOnly]="isReadOnly"
                (onSave)="updateAmount(i,{deductValue: $event})"></app-price>
            </div>
            <div *ngIf="!amount?.resourceId" class="w-1/5">
              <app-select [label]="'Resource'" [value]="amount?.resource"
                [list]="resources" [isReadOnly]="isReadOnly" (onSave)="updateAmount(i,{resource: $event, resourceId:'', resourceName: ''})">
              </app-select>
            </div>
            <div [ngClass]="amount?.resourceId?'w-1/2':'w-1/3'">
              <div *ngIf="amount?.resourceId" class="relative bg-gray-50 rounded-lg w-full">
                <a
                  id="typeahead-focus"
                  type="text"
                  [routerLink]="[]"
                  (click)="openResource(amount.resource, amount.resourceId)"
                  class="bg-gray-50 flex items-center px-2.5 w-full input_style text-sm text-blue-600 bg-transparent font-poppins rounded-lg z-10 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  style="border: 1px solid rgb(209, 213, 219);"
                ><i class="fa-solid fa-arrow-up-right-from-square mr-1"></i>{{amount.resourceName}}</a>
                <label class="absolute bg-gray-50 rounded-2xl text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:py-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"></label>
                <fieldset class="jss1"><legend class="jss3"><span></span></legend></fieldset>
                <app-btn-confirm *ngIf="currentUser?._id !== expenseForm.get('employeeId').value"
                  class="removeBtn top-2 absolute-right-2" [isReadOnly]="isReadOnly" (onConfirm)="updateAmount(i, {resourceId:'', resourceName: ''})"></app-btn-confirm>
              </div>
              <div *ngIf="amount.resource === FOLLOW_UP_RESOURCE.TRIP">
                <app-search
                  *ngIf="!amount?.resourceId"
                  [idInfo]="'filter-trip'"
                  [label]="'Trip'"
                  [resource]="'TRIP'"
                  [method]="'getAll'"
                  [icon]="'fa-solid fa-search'"
                  (onChange)="setTrip(i, $event)"
                ></app-search>
              </div>
              
              <div *ngIf="amount.resource === FOLLOW_UP_RESOURCE.ORDER">
                  <app-search
                    *ngIf="!amount.resourceId"
                    [idInfo]="'order-search'"
                    [label]="'Order'"
                    [resource]="'ORDER'"
                    [isReadOnly]="isReadOnly" 
                    [method]="'getAll'"
                    [options]="{focus: true}"
                    (onChange)="setOrder(i, $event)"
                  ></app-search>
              </div>

              <div *ngIf="amount.resource === FOLLOW_UP_RESOURCE.CLAIM">
                <app-search
                  *ngIf="!amount.resourceId"
                  [idInfo]="'claim-search'"
                  [label]="'Claim'"
                  [resource]="'CLAIM'"
                  [isReadOnly]="isReadOnly"
                  [method]="'getAll'"
                  [limit]="25"
                  (onChange)="setClaim(i, {claimId: $event._id, claimName: $event.autoIncr})"
                ></app-search>
              </div>
            </div>
            <div class="ml-auto flex items-center">
              <button [disabled]="isReadOnly" (click)="removeAmount(i)" class="rounded-full bg-red-100 text-red-900" style="width:20px; height:20px">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            
          </div>
        }
        <div class="mt-2 flex items-center mb-4">
          <button (click)="addAmount()" class="rounded-full btn-bg-green" style="width:30px; height:30px"><i style="color:white" class="fa-solid fa-plus"></i></button>
          <span *ngIf="expenseForm.get('amounts').value.length > 1" class="text-base ml-2"><b>Total Amount:</b> ${{ expenseForm.get('amounts').value | sum : 'value' | myCurrency}}</span>
        </div>
        
        <div class="w-100 mb-4">
          <div class="flex gap-2">
            <div [ngClass]="expenseForm.get('paymentType').value === 'check'?'w-1/2':'w-full'">
              <app-select [value]="expenseForm.get('paymentType').value" [label]="'Payment Type'" [list]="paymentTypes" [invalid]="expenseForm.get('paymentType').invalid &&
              (expenseForm.get('paymentType').dirty || expenseForm.get('paymentType').touched)" (onSave)="setValue($event, 'paymentType')"></app-select>
            </div>
            <div *ngIf="expenseForm.get('paymentType').value === 'check'" class="w-1/2">
              <app-input [value]="expenseForm.get('checkNo').value" [label]="'Check Number'" [isReadOnly]="isReadOnly" (onSave)="expenseForm.get('checkNo').setValue($event)"></app-input>
            </div>
          </div>
          

          <div *ngIf="expenseForm.get('paymentType').invalid && (expenseForm.get('paymentType').dirty || expenseForm.get('paymentType').touched)" class="invalid-feedback">
            Please input Payment Type!
          </div>
          

          <app-btn-toggle [value]="expenseForm.get('reimbursement').value" [label]="'Reimbursement'" (onToggle)="setValue($event, 'reimbursement')"></app-btn-toggle>
        </div>

        <div class="w-100 mb-4">
          <textarea 
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            autogrow
            formControlName="desc"
            [attr.disabled]="isReadOnly?true:null"
            placeholder="Description"
            ></textarea>
        </div>
      
        <div class="w-100 mb-4">
          <div class="mb-2" style="width:50%">
            <app-filters [status]="[{ name: 'Notes', key: 'notes' },
            { name: 'Tasks', key: 'tasks' }]" [filters]="{selectedTab}" [property]="'selectedTab'" (filterSet)="selectedTab = $event.selectedTab"></app-filters>
          </div>

          <div class="flex flex-col">
            <app-notes [style.display]="selectedTab === 'notes'?'block':'none'" [isReadOnly]="isReadOnly"
              [options]="{ list: true }" [creator]="currentUser" [usersEntities]="users" [title]="''" [display]="{
                tabs: { notes: true },
                select: {
                  assignee: true,
                  visibility: true,
                  date: true,
                  typeTask: true,
                  complete: true,
                  title: 'Expense Notes'
                }
              }" [creator]="currentUser"
              [resource]="'EXPENSE'"
              [resourceId]="_expense?._id"
              ></app-notes>
            <app-tasks-container [style.display]="selectedTab === 'tasks'?'block':'none'" id='candidate-tasks'
              [currentUser]="currentUser" [resource]="{
                resource: FOLLOW_UP_RESOURCE.EXPENSE,
                resourceId: _expense?._id,
                resourceName: _expense?.expenseNumber
              }"></app-tasks-container>
          </div>
        </div>
        <div *ngIf="_expense?.simpleHistory?.length" class="w-100 mb-4">
          <div class="col">
            <h5>History</h5>
            @for(history of _expense?.simpleHistory; track $index){
              <div class="col p-0 mt-3 pb-2"
                style="border-bottom:1px solid #e1e5ee">
                <span *ngIf="history?.to !=='ACCEPTED'">Status has been changed from {{getStatusName(history?.from)}} to
                  {{getStatusName(history?.to)}}. By {{history?.creator?.name}}</span>
                <span *ngIf="history?.to ==='ACCEPTED'" style="font-weight:600;"> Approved by
                  {{history?.creator?.name}}</span>
              </div>
            }
          </div>
        </div>
        <div class="w-100 mb-4">
          <app-files *ngIf="_expense?._id" [title]="'Expense Files'" [resource]="'EXPENSE'"
            [resourceId]="_expense?._id"></app-files>
        </div>
      </div>
    </div>
  </form>
  <span *ngIf="_expense?.updated">Last Modified:
    {{ _expense?.updated?.at | date: 'short' }} |
    <app-account-info-popover [creator]="_expense?.updated?.by" style="cursor: pointer;"></app-account-info-popover>
  </span>
  <div *ngIf="!isReadOnly && !_expense?.deleted" class="flex justify-end gap-3 mt-4 mb-4">
    <button *ngIf="!_expense?.orderId" class='px-3 py-2 rounded-md bg-gray-700 text-white' type="submit" (click)="createOrder()">Create Work
      Order</button>
    <a [routerLink]="'shop/'+_expense?.orderId" class='px-3 py-2 rounded-md bg-gray-700 text-white'  target="_blank" *ngIf="_expense?.orderId">Go to Work Order</a>
    <button *appHasPermission="'expenses_delete'" class='px-3 py-2 rounded-md bg-red-700 text-white'  type="submit"
      (click)="onDelete.emit(expenseForm.value)">Delete</button>
    <button *appHasPermission="'expenses_update'" class='px-3 py-2 rounded-md bg-blue-700 text-white' type="submit" (click)="save()">Save
      Expense</button>
  </div>
  <div *ngIf="_expense?.deleted" class="modal-footer">
    <button *appHasPermission="'expenses_update'" class='btn btn-success' type="submit"
      (click)="restore()">Restore</button>
  </div>
  <app-simple-history id="expense-history" [resource]="FOLLOW_UP_RESOURCE.EXPENSE"></app-simple-history>
</div>
