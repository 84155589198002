import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core'
import { DatatableComponent } from '@swimlane/ngx-datatable'
import { AuthService } from '@app/users/services'

import { Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import * as fromStore from '@app/shared/store'
import { ActivatedRoute, Router } from '@angular/router'

import { Expense, ExpenseStats } from '@app/shared/models/expense.model'

import { ToastHelper } from '@app/core/services/toast.service'
import { OpenModal } from '@app/modals/store/actions/layout.actions'
import { FOLLOW_UP_RESOURCE } from '@app/shared/models'
import moment from 'moment'

@Component({
    selector: 'app-expense-list',
    templateUrl: './expense-list.component.html',
    styleUrls: ['./expense-list.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseListComponent implements OnDestroy {
    @ViewChild(DatatableComponent, { static: false })
    table: DatatableComponent
    @Input()
    expenses: Expense[]
    @Input()
    totalCount
    @Input()
    stats: ExpenseStats[]
    @Input()
    currentUser
    @Input()
    categories
    @Input()
    accountsCharts
    @Input()
    selectedId
    @Input()
    loading
    @Input()
    loaded

    page
    @Input()
    set filters(v) {

        v?.page ? this.page = v.page : this.page = 1
        this._filters = v

        
        const keys = this._filters ? Object.keys(v) : []
        this.filterExists = keys.filter(key=>{
            if((key!=='page' && key!=='limit') && v[key]){
                return true
            }
        }).length ? true : false

        this.pagination.offset = this._filters ? this._filters.page - 1 : 0
        this.pageFilter.page = this._filters ? this._filters.page : 1
    };
    _filters
    filterExists = false

    pagination = { limit: 25, offset: 0 };
    pageFilter = { limit: 25, page: 1 };
    defaultPageFilter = { limit: 25, page: 1 };

    filterIsOpen = true;
    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

    destroyed$ = new Subject<boolean>();
    statuses = {
        main: [
            { name: 'ALL' },
            { name: 'NEW', key: 'NEW' },
            { name: 'IN REVIEW', key: 'IN_REVIEW' },
            { name: 'ACCEPTED', key: 'ACCEPTED' },
            { name: 'DENIED', key: 'DENIED' }
        ],
        accepted: [
            { name: 'ALL' },
            { name: 'NOT-CLOSED', key: 'NOT_CLOSED' },
            { name: 'CLOSED', key: 'CLOSED' }
        ]
    }
    firstDay
    lastDay
    limitOptions = [{key:'25',name:'Show 25'}, {key:'50',name:'Show 50'}, {key:'75',name:'Show 75'}, {key:'100',name:'Show 100'}]
    constructor(
        private store: Store<fromStore.State>,
        public notify: ToastHelper,
        private _Router: Router,
        private route: ActivatedRoute, 
        // private router: Router, private route: ActivatedRoute
    ) {
        const now = new Date();
        this.firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        this.lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    }

    openModal() {
        this.store.dispatch(
            new OpenModal({
                type: 'EXPENSE'
            })
        )
    }
    setPage($event) {
        this._filters = { ...this._filters, page: $event }
        this.store.dispatch(new fromStore.SetExpenseFilter(this._filters))
    }
    updateFilter(filter) {
        this.store.dispatch(
            new fromStore.SetExpenseFilter({ ...this._filters, ...filter, ...this.defaultPageFilter, })
        )
    }
    resetFilter(filter) {
        this.store.dispatch(new fromStore.SetExpenseFilter(null))
    }
    openContactModal(contactId) {
        this.store.dispatch(
            new OpenModal({
                type: 'EditContact',
                props: { contactId }
            })
        )
    }
    getStatusName(key) {
        return this.statuses.main.find(s => s.key === key)?.name
    }
    create = () => {
        this.store.dispatch(new fromStore.CreateExpense({ expense: {} }))
    }
    edit = (expense: Expense) =>
        this.store.dispatch(
            new OpenModal({
                type: 'EXPENSE',
                props: {
                    _id: expense?._id
                },
            })
        )
        openResource(resource, resourceId) {
            if (resource === FOLLOW_UP_RESOURCE.TRIP) {
                const params = {
                    report: 'byTrip',
                    startDate: moment(new Date('08/01/17')).startOf('month').format('MM/DD/YY'),
                    endDate: moment().startOf('month').format('MM/DD/YY'),
                    status: 'ACTIVE',
                    ordersType: 'ALL',
                    tripsIds: resourceId
                }
                const url = this._Router.serializeUrl(this._Router.createUrlTree(['/reports'], { queryParams: { ...params } }));
                let baseUrl = window.location.href.replace(this._Router.url, '');
                window.open(baseUrl+url, '_blank');
            } else if (resource === FOLLOW_UP_RESOURCE.ORDER) {
                const url = this._Router.serializeUrl(this._Router.createUrlTree([`/loadboard/${resourceId}`]));
                let baseUrl = window.location.href.replace(this._Router.url, '');
                window.open(baseUrl+url, '_blank');
            } else if (resource === FOLLOW_UP_RESOURCE.CLAIM) {
                const url = this._Router.serializeUrl(this._Router.createUrlTree([`/claims/${resourceId}`]));
                let baseUrl = window.location.href.replace(this._Router.url, '');
                window.open(baseUrl+url, '_blank');
            } else {
                return
            }
        }

    openInReports(tripId) {
        this._Router.navigate(
          [`/reports`],
          {
            queryParams: {
              report: 'byTrip',
              startDate: moment(new Date('08/01/17')).startOf('month').format('MM/DD/YY'),
              endDate: moment().startOf('month').format('MM/DD/YY'),
              status: 'ACTIVE',
              ordersType: 'ALL',
              tripsIds: `${tripId}`
            }
          }
        )
      }
    
      openInTrips(tripId) {
        this._Router.navigate([`/trips`, tripId])
      }

    rowClass = (row) => {
        return {'selected-row': row._id === this.selectedId} 
      }

    hasAccessToTrip = trip => trip?.creator?.companyId === AuthService.CURRENT_USER?.company?._id && (!this.currentUser?.permissions?.contact_roles_driver || trip?.driverIds?.includes(this.currentUser?._id))

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
