import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromStore from '@app/shared/store'
import * as fromAuth from '@app/users/store'
import * as fromModalStore from '@app/modals/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators'
import { CloseModal } from '@app/modals/store/actions/layout.actions'
import { selectAllUsers } from '@app/sidebar/store/selectors/user.selectors'
import { Expense, UrlShortenService } from '@app/shared'
import { File } from '@app/core/models'
import { environment } from 'environments/environment'
import { FOLLOW_UP_RESOURCE, LockedItem } from '@app/shared/models'
import { Router } from '@angular/router'
import { LockedItemWorkerFactory, LockItemWorker } from '@app/core/services'
import { getTaskTotalCount } from '@app/shared/store'
import { AccountChart } from '@app/shared/models/account-chart.model'
import { popupService } from '@app/shared/services/popup.service'

@Component({
    selector: 'app-expense-modal',
    templateUrl: './expense-modal.component.html',
    styleUrls: ['./expense-modal.component.scss']
})
export class ExpenseModalComponent implements OnInit, OnDestroy {
    @Input()
    props

    destroyed$ = new Subject<boolean>()
    expense
    files = []
    fullPaths = []
    filter
    permissions
    isReadOnly = false
    currentUser$
    users$
    categories$: Observable<any>
    taskCount$
    accountsCharts$: Observable<AccountChart[]>

    lockedItem$: Observable<LockedItem>
    askToUnlockListener$: Observable<number>
    lockedItemWorker: LockItemWorker

    apiUrl = environment.apiUrl
    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

    TABS = {
        DETAILS: 'Details',
        HISTORY: 'History'
    }
    selectedTab: string = this.TABS.DETAILS

    constructor(private store: Store<fromModalStore.State>,
        private coreStore: Store<fromCoreStore.State>,
        private lockedItemWorkerFactory: LockedItemWorkerFactory,
        private _Router: Router,
        private cdRef: ChangeDetectorRef,
        private popupService: popupService
        ) { }
        

    ngOnInit(): void {
        this.currentUser$ = this.store.select(fromAuth.getUser).pipe(
            map((user)=>{
                if(user){
                    this.store.dispatch(new fromCoreStore.GetAccountsCharts({filters:{companyId:user.company._id}}))
                }
                return user
            })
        )
        this.users$ = this.store.select(selectAllUsers)
        this.taskCount$ = this.store.select(getTaskTotalCount)
        this.accountsCharts$ = this.store.select(fromCoreStore.getAccountChartsByParent)
        this.fullPaths = []
        this.store.dispatch(new fromCoreStore.GetExpenseCategories({}))
        this.coreStore.dispatch(new fromCoreStore.GetOneExpense({ _id: this.props._id }))
        this.coreStore.dispatch(new fromCoreStore.GetExpenseSimpleHistory({ _id: this.props._id }))
        this.store.dispatch(new fromCoreStore.GetFiles({ resource: 'EXPENSE', resourceId: this.props._id }))
        this.coreStore.select(fromCoreStore.selectExpenseById(this.props._id)).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(expense => {
            if (expense) {
                if (expense?.deleted) this.isReadOnly = true
                this.expense = { ...expense, employeeId: expense?.employee?.employeeId || '', employeeName: expense?.employee?.name || '', vendorId: expense?.vendor?._id || '', vendorName: expense?.vendor?.name || '' }
            }
            this.cdRef.detectChanges()
        })
        this.store.select(fromCoreStore.selectFilesByResource('EXPENSE', [this.props._id])).pipe(
            takeUntil(this.destroyed$))
            .subscribe(
                (files) => {
                    this.files = files
                }
            )
        // this.coreStore.select(fromStore.selectExpenseFilter).subscribe(
        //     (f) => {
        //         this.filter = f
        //         this.store.dispatch(new fromCoreStore.GetExpenses(this.filter ? this.filter : {}))
        //     }
        // )
        this.categories$ = this.store.select(fromStore.selectExpenseCategories)
        this.lockedItem$ = this.coreStore.select(fromCoreStore.selectExpenseById(this.props._id)).pipe(
            distinctUntilChanged((prev, curr) => prev?._id === curr?._id),
            tap(
                expense =>
                (this.lockedItemWorker = this.lockedItemWorkerFactory.createLockItemWorker(
                    expense?._id,
                    FOLLOW_UP_RESOURCE.EXPENSE,
                    fromCoreStore,
                    {
                        changeWindowTitle: true,
                    }
                ))
            ),
            switchMap(() => this.lockedItemWorker.listenAndLock())
        )
        this.coreStore
            .select(fromAuth.getPermissions)
            .pipe(
                takeUntil(this.destroyed$),
                switchMap(permissions =>
                    this.coreStore.select(fromCoreStore.checkLockedItemLock(this.props._id)).pipe(
                        tap(lock => {
                            this.permissions = permissions
                            this.isReadOnly = !permissions?.['expenses_update'] || !lock.lockedByCurrentTab
                        })
                    )

                )
            )
            .subscribe()
    }

    updateExpense(data) {
        this.coreStore.dispatch(
            new fromCoreStore.UpdateExpense({
                expense: { id: data._id, changes: data },
                closeModal: false
            })
        )
    }
    save(data) {
        if (data._id) {
            this.coreStore.dispatch(
                new fromCoreStore.UpdateExpense({
                    expense: { id: data._id, changes: data },
                    closeModal: true
                })
            )
            this.close()
        }
    }
    onDelete = async (data) => {
        if (await this.popupService.confirm(`Are you sure you want to delete?`)) {
            this.store.dispatch(new fromStore.DeleteExpense(data))
            this.close()
        }
    }

    close() {
        this.store.dispatch(new CloseModal())
    }

    // deletePhoto() {
    //     const file = this.files[this.viewer.currentPage()]
    //     if (file && confirm("Are you sure you want to delete this photo?")) {
    //         const filesIds = this.expense.filesIds.filter(id => id !== file._id)
    //         this.updateExpense({ _id: this.expense._id, filesIds })
    //         this.store.dispatch(
    //             new fromCoreStore.DeleteFile({ _id: file._id })
    //         )
    //     }
    // }

    // downloadPhoto() {
    //     const file = this.files[this.viewer.currentPage()]
    //     if (file.url) {
    //         delete file.url
    //     }
    //     if (file.fullPath) {
    //         delete file.fullPath
    //     }
    //     this.store.dispatch(
    //         new fromCoreStore.ApiDownloadFile({ file, preview: false })
    //     )
    // }

    askToUnlock = () => (this.askToUnlockListener$ = this.lockedItemWorker.askToUnlock())
    updateLock = () => this.lockedItemWorker.updateLock()

    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
